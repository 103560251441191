/* unplugin-vue-components disabled */var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vue-custom-scrollbar',{staticClass:"scroll",attrs:{"settings":_vm.scrollSettings}},[_c('div',[_c('div',{staticClass:"product-info"},[_c('div',{staticClass:"image-block"},[_c('img',{staticClass:"image",attrs:{"src":_vm.product.image},on:{"error":function($event){$event.target.src = require('@/assets/images/img.svg')}}})]),_c('div',{staticClass:"attributes"},[_c('div',{staticClass:"attributes-column"},[_c('div',{staticClass:"attributes-table"},[_c('div',{staticClass:"attribute"},[_c('div',{staticClass:"attribute-name"},[_vm._v("SKU:")]),_c('div',{staticClass:"attribute-value"},[_c('a',{attrs:{"href":_vm.product.mp_url,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.product.sku)+" ")])])]),_c('div',{staticClass:"attribute"},[_c('div',{staticClass:"attribute-name"},[_vm._v("品牌：")]),_c('div',{staticClass:"attribute-value"},[(_vm.product.brand === '&:NA')?[_c('span',{staticClass:"gray"},[_vm._v(" — ")])]:[_c('router-link',{attrs:{"to":{
                                            name: 'BrandInfo',
                                            params: {
                                                b_id: _vm.product.b_id,
                                            },
                                            query: {
                                                date: this.reportSettings
                                                    .date,
                                                date2: this.reportSettings
                                                    .date2,
                                                fbs: this.reportSettings
                                                    .fbs,
                                            },
                                        },"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.product.brand)+" ")])]],2)]),_c('div',{staticClass:"attribute"},[_c('div',{staticClass:"attribute-name"},[_vm._v("业务员：")]),_c('div',{staticClass:"attribute-value"},[(_vm.product.seller === '&:NA')?[_c('span',{staticClass:"gray"},[_vm._v(" — ")])]:([ 'Ozon', 'ООО ВАЙЛДБЕРРИЗ', 'ВАЙЛДБЕРРИЗ ООО' ].includes(_vm.product.seller))?[_c('span',{staticClass:"gray",domProps:{"innerHTML":_vm._s(_vm.product.seller)}})]:[_c('router-link',{attrs:{"to":{
                                            name: 'SellerInfo',
                                            params: {
                                                s_id: _vm.product.s_id,
                                            },
                                            query: {
                                                date: this.reportSettings
                                                    .date,
                                                date2: this.reportSettings
                                                    .date2,
                                                fbs: this.reportSettings
                                                    .fbs,
                                            },
                                        },"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.product.seller)+" ")])]],2)])])]),_c('div',{staticClass:"attributes-column"},[_c('div',{staticClass:"attributes-table"},[_c('div',{staticClass:"attribute"},[_c('div',{staticClass:"attribute-name"},[_vm._v("第一次发现：")]),_c('div',{staticClass:"attribute-value"},[_vm._v(" "+_vm._s(_vm.dateFormat(_vm.product.date))+" ")])]),_c('div',{staticClass:"attribute"},[_c('div',{staticClass:"attribute-name"},[_vm._v("最后出现：")]),_c('div',{staticClass:"attribute-value"},[_vm._v(" "+_vm._s(_vm.dateFormat(_vm.product.date2))+" ")])])])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }