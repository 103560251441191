//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DataTable from "@/components/DataTable/DataTable";
import CategoryPath from "@/components/Category/Path";
import CategoriesPositionsChart from "@/components/Product/CategoriesPositionsChart";
import { dateRangeDaysArray, dateFormat } from "@/utils/dates";
export default {
  components: {
    DataTable,
    CategoryPath,
    CategoriesPositionsChart
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    reportSettings: {
      type: Object,
      required: true
    }
  },
  methods: {
    tableLoader({
      query
    }) {
      return this.$store.dispatch("product/getProductCategoriesPositions", {
        ...query,
        ...this.reportSettings
      });
    }
  },
  computed: {
    columns() {
      const days = dateRangeDaysArray(this.reportSettings.date, this.reportSettings.date2);
      let position = 3;
      return [{
        title: "类别",
        position: 1,
        show: true,
        name: "c_name_path",
        width: 440,
        filter: "text",
        type: "slot"
      }, {
        title: "动态变化",
        position: 2,
        show: true,
        name: "dynamic",
        width: 200,
        type: "slot"
      }, ...days.map(date => {
        return {
          title: dateFormat(date),
          position: ++position,
          show: true,
          name: date,
          width: 100,
          type: "number",
          filter: "numberRange",
          noSettings: true
        };
      })];
    }
  }
};