//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DataTable from "@/components/DataTable/DataTable";
export default {
  components: {
    DataTable
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    reportSettings: {
      type: Object,
      required: true
    }
  },
  methods: {
    tableLoader({
      query
    }) {
      return this.$store.dispatch("product/getProductDays", {
        ...query,
        ...this.reportSettings
      });
    }
  },
  computed: {
    columns() {
      let a = [{
        title: "日期",
        show: true,
        name: "date",
        width: 100,
        type: "date"
      }];
      if (this.reportSettings.mp == "wb") {
        a = a.concat([{
          //title: "价格",
          title: "带WB钱包的价格",
          //wb-no-spp
          show: true,
          name: "price",
          type: "money",
          width: 100,
          filter: "numberRange"
        },
        /*
        {
            title: "SPP",
            show: true,
            name: "price_discount_spp",
            type: "percent",
            width: 100,
            filter: "numberRange",
        },
        */
        {
          //title: "基本价格",
          title: "价格",
          //wb-no-spp
          show: true,
          name: "price_spp",
          type: "money",
          width: 100,
          filter: "numberRange"
        }, {
          title: "折扣",
          show: true,
          name: "discount_wb",
          type: "percent",
          width: 100,
          filter: "numberRange"
        }, {
          title: "旧价格",
          show: true,
          name: "price_old",
          type: "money",
          width: 100,
          filter: "numberRange"
        }]);
      } else {
        a = a.concat([{
          title: "价格",
          show: true,
          name: "price",
          type: "money",
          width: 100,
          filter: "numberRange"
        }, {
          title: "旧价格",
          show: true,
          name: "price_old",
          type: "money",
          width: 100,
          filter: "numberRange"
        }, {
          title: "折扣",
          show: true,
          name: "discount",
          type: "percent",
          width: 100,
          filter: "numberRange"
        }]);
      }
      a = a.concat([{
        title: "当前余额(个)",
        show: true,
        name: "remains",
        type: "number",
        width: 120,
        filter: "numberRange"
      }, {
        title: "销售",
        show: true,
        name: "sold",
        type: "number",
        width: 100,
        filter: "numberRange"
      }, {
        title: "营收",
        show: true,
        name: "revenue",
        type: "money",
        width: 100,
        filter: "numberRange"
      }, {
        title: "评论",
        show: true,
        name: "reviews",
        type: "number",
        width: 100,
        filter: "numberRange"
      }, {
        title: "新评论",
        show: true,
        name: "new_reviews_1day",
        type: "number",
        width: 100,
        filter: "numberRange"
      }, {
        title: "评分",
        show: true,
        name: "rating",
        type: "number",
        width: 100,
        filter: "numberRange"
      }, {
        title: "类别数",
        show: true,
        name: "categories",
        type: "number",
        width: 100,
        filter: "numberRange"
      }]);
      return a.map((item, i) => {
        item.position = i + 1;
        return item;
      });
    }
  }
};