//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DataTable from "@/components/DataTable/DataTable";
////import CategoryPath from '@/components/Category/Path'
import KeywordsPositionsChart from "@/components/Product/KeywordsPositionsChart";
import { dateRangeDaysArray, dateFormat } from "@/utils/dates";
export default {
  //components: { DataTable, CategoryPath, CategoriesPositionsChart },
  components: {
    DataTable,
    KeywordsPositionsChart
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    reportSettings: {
      type: Object,
      required: true
    }
  },
  methods: {
    tableLoader({
      query
    }) {
      return this.$store.dispatch("product/getProductKeywordsPositions", {
        ...query,
        ...this.reportSettings
      });
    }
  },
  computed: {
    columns() {
      const days = dateRangeDaysArray(this.reportSettings.date, this.reportSettings.date2);
      let position = 3;
      return [{
        title: "要求",
        show: true,
        name: "keyword",
        width: 440,
        filter: "text",
        type: "text"
      }, {
        title: "频率" + (this.reportSettings.mp === "wb" ? "WB" : "OZON"),
        show: true,
        name: "shows",
        type: "number",
        width: 120,
        filter: "numberRange"
      }, {
        title: "根据要求提供结果",
        show: true,
        name: "tot_keyword_products",
        ////type: "number",
        width: 140,
        filter: "numberRange",
        type: "link",
        getLink: item => {
          return {
            href: item.keyword_mp_search_link,
            text: item.tot_keyword_products,
            target: "_blank"
          };
        }
      }, {
        title: "根据要求提供平均排名",
        show: true,
        name: "avg_position",
        type: "number",
        width: 160,
        filter: "numberRange"
      }, {
        title: "动态变化",
        position: 2,
        show: true,
        name: "dynamic",
        width: 200,
        type: "slot"
      }, ...days.map(date => {
        return {
          title: dateFormat(date),
          position: ++position,
          show: true,
          name: date,
          width: 100,
          type: "number",
          filter: "numberRange",
          noSettings: true
        };
      })];
    }
  }
};