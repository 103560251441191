//
//
//
//

import SmallChart from "@/components/charts/SmallChart";
import { dateRangeDaysArray } from "@/utils/dates";
export default {
  props: {
    date: {
      type: String,
      required: true
    },
    date2: {
      type: String,
      required: true
    },
    item: {
      type: Object,
      required: true
    }
  },
  components: {
    SmallChart
  },
  computed: {
    dataset() {
      return dateRangeDaysArray(this.date, this.date2).map(day => this.item[day] || 0);
    }
  }
};