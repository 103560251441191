//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DataTable from "@/components/DataTable/DataTable";
import { dateRangeDays } from "@/utils/dates";
export default {
  components: {
    DataTable
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    reportSettings: {
      type: Object,
      required: true
    }
  },
  methods: {
    tableLoader({
      query
    }) {
      return this.$store.dispatch("product/getProductSizes", {
        ...query,
        ...this.reportSettings
      });
    }
  },
  computed: {
    columns() {
      const days = dateRangeDays(this.reportSettings.date, this.reportSettings.date2);
      return [{
        title: "尺寸",
        show: true,
        name: "size",
        width: 100,
        filter: "text"
      }, {
        title: `已售 ${days} 天(件)`,
        show: true,
        name: "sold",
        type: "number",
        width: 180,
        filter: "numberRange"
      }, {
        title: `${days} 天的收入`,
        show: true,
        name: "revenue",
        type: "money",
        width: 180,
        filter: "numberRange"
      }, {
        title: "当前余额(个)",
        show: true,
        name: "remains",
        type: "number",
        width: 120,
        filter: "numberRange"
      }, {
        title: `平均. 日销量(个)`,
        show: true,
        name: "avg_sold",
        type: "number",
        width: 180,
        filter: "numberRange"
      }, {
        title: `平均. 每日收入`,
        show: true,
        name: "avg_revenue",
        type: "money",
        width: 180,
        filter: "numberRange"
      }, {
        title: `库存天数`,
        show: true,
        name: "days",
        type: "number",
        width: 180,
        filter: "numberRange"
      }, {
        title: `收入损失`,
        show: true,
        name: "losses",
        type: "money",
        width: 180,
        filter: "numberRange"
      }].map((item, i) => {
        item.position = i + 1;
        return item;
      });
    }
  }
};