//
//
//
//
//
//
//
//
//
//

import ModalListAddItems from "@/components/modals/ModalListAddItems.vue";
export default {
  components: {
    ModalListAddItems
  },
  props: {
    entity: {
      type: String,
      required: true
    },
    mp: {
      type: String,
      required: true
    },
    item: {
      type: Object,
      required: true
    }
  },
  methods: {
    onClick() {
      this.$access.hasOrThrow("lists");
      this.$refs.modalListAddItems.open({
        items: [this.item],
        entity: this.entity,
        mp: this.mp
      });
    }
  }
};